import { createSlice } from "@reduxjs/toolkit";
import apiService from "../../utils/apiServices";

export const initialState = {
  dataFirst: {},
  reviewPoint: {},
  dashboardResolution: {}
};
export const analytic = createSlice({
  name: "analytic",
  initialState,
  reducers: {
    setFirstAnalytic(state, action) {
       state.dataFirst = action.payload
    },
    setAnalyticReviewPoint(state, action) {
      state.reviewPoint = action.payload
    },
    setDashboardResolution(state, action) {
      state.dashboardResolution = action.payload
    },
  },
});

const getAnalyticFirst = (param) => {
  return async (dispatch) => {
    try {
      const firstTime = await apiService.get('analytic/first', param)
      dispatch(analytic.actions.setFirstAnalytic(firstTime));
      return firstTime;
    } catch (error) {
      console.log(error);
    }
  };
};

const getAnalyticReviewPoint = (param) => {
  return async (dispatch) => {
    try {
      const agentReview = await apiService.get('agent/reviews', param)
      dispatch(analytic.actions.setAnalyticReviewPoint(agentReview));
      return agentReview;
    } catch (error) {
      console.log(error);
    }
  };
};

const getDashboardResolution = (param) => {
  return async (dispatch) => {
    try {
      const responseResolution = await apiService.get('analytic/dashboard-resolutions', param)
      dispatch(analytic.actions.setDashboardResolution(responseResolution));
      return responseResolution;
    } catch (error) {
      console.log(error);
    }
  };
};

export const analyticActions = {
  ...analytic.actions,
  getAnalyticFirst,
  getAnalyticReviewPoint,
  getDashboardResolution
};
export default analytic.reducer;
