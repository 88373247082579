export { default as apiService } from './apiServices'
export function formatTime(timestamp, isAbsSecond = false) {
  if (isNaN(timestamp)) {
    timestamp = 0
  }
  const checkNagative = timestamp < 0
  const newTimestamp = Math.floor(Math.abs(timestamp) / 1000)
  const hours = Math.floor(newTimestamp / 60 / 60)

  const minutes = Math.floor(newTimestamp / 60) - hours * 60

  const seconds = isAbsSecond ? Math.abs(newTimestamp % 60) : newTimestamp % 60
  const formatHour = checkNagative ? `-${hours}` : hours

  return formatHour + 'h:' + minutes + 'm:' + seconds + 's'
}

export function getDiffdays(day1, day2) {
  const date1 = new Date(day1)
  const date2 = new Date(day2)

  const diffTime = Math.abs(date2 - date1)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (diffDays <= 1) return diffDays

  return diffDays - getWeekendCountBetweenDates(date1, date2)
}

function getWeekendCountBetweenDates(startDate, endDate) {
  var totalWeekends = 0
  for (var i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
    if (i.getDay() == 0 || i.getDay() == 1) totalWeekends++
  }
  return totalWeekends
}
export function getAverageTime(times, during = 1) {
  if (!times || !times.length) return null
  var count = times.length
  var timesInSeconds = []
  // loop through times
  for (var i = 0; i < count; i++) {
    // parse
    var pieces = times[i].split(':')
    var ampm = pieces[2].split(' ')
    var hrs = Number(pieces[0]) === 12 ? 0 : Number(pieces[0])
    var mins = Number(pieces[1])
    var secs = Number(ampm[0])
    ampm = ampm[1]
    // convert to 24 hr format (military time)
    if (ampm === 'PM') {
      hrs = hrs + 12
      if (during === 2) {
        hrs = hrs - 24
        if (mins > 0) {
          hrs = hrs + 1
        }
        mins = 60 - mins
        secs = 60 - secs
      }
    }

    // find value in seconds of time
    var totalSecs = hrs * 60 * 60
    totalSecs += mins * 60
    totalSecs += secs
    if (ampm === 'PM' && during === 2) {
      totalSecs *= -1
    }

    // add to array
    timesInSeconds[i] = totalSecs
  }

  // find average timesInSeconds
  var total = 0
  for (var j = 0; j < count; j++) {
    total = total + Number(timesInSeconds[j])
  }

  var avg = Math.round(total / count)
  // turn seconds back into a time
  var avgMins = Math.floor(avg / 60)
  var avgSecs = avg - 60 * avgMins
  var avgHrs = Math.floor(avgMins / 60)
  avgMins = avgMins - 60 * avgHrs

  // convert back to 12 hr. format
  var avgAmpm = 'AM'
  if (avgHrs > 12 || avgHrs < 0) {
    avgAmpm = 'PM'
    if (during === 2) {
      avgHrs = avgHrs + 12
    } else {
      avgHrs = avgHrs - 12
    }
  }
  // add leading zeros for seconds, minutes
  avgSecs = ('0' + avgSecs).slice(-2)
  avgMins = ('0' + avgMins).slice(-2)
  // your answer
  return avgHrs + ':' + avgMins + ':' + avgSecs + ' ' + avgAmpm
}
export function parseParams(params) {
  var queryString = Object.keys(params)
    .filter((key) => params[key] || (Array.isArray(params[key]) && params[key].length > 0))
    .map((key) => key + '=' + params[key])
    .join('&')
  return queryString
}

export function ampmToTimestamp(time, hasSeconds = true) {
  if (!time) return 0
  const arrTime = time.split(':').map((item, index) => {
    if (index === (hasSeconds ? 2 : 1)) return +item.slice(0, -3)
    return +item
  })

  let hours = 0

  if (time.includes('PM')) {
    hours = arrTime[0] + 12
  } else {
    hours = arrTime[0] === 12 ? 0 : arrTime[0]
  }

  return hours * 60 * 60 + arrTime[1] * 60 + (hasSeconds ? arrTime[2] : 0)
}

export function parseAgentsToCaculate(agents) {
  const parseAgentsCurrent = agents.reduce(
    (a, b, index) => {
      a.sessionPerDay += +b.avgChat
      a.availableTime += compareFirst(b.data)
      a.messPerChat += +b['totalMessage'] / +b['totalForCaculate']
      a.firstResponse += +b.avgTimeFirstResponse
      a.userWaittime += +b.avgTimeProcessing
      a.totalMessage += +b['totalMessage']
      a.totalForCaculate += +b['totalForCaculate']

      return a
    },
    {
      sessionPerDay: 0,
      availableTime: 0,
      messPerChat: 0,
      userWaittime: 0,
      firstResponse: 0,
      totalMessage: 0,
      totalForCaculate: 0,
    },
  )

  parseAgentsCurrent.messPerChat =
    parseAgentsCurrent.totalMessage / parseAgentsCurrent.totalForCaculate

  for (const key in parseAgentsCurrent) {
    parseAgentsCurrent[key] = parseAgentsCurrent[key] / agents.length
  }
  return parseAgentsCurrent
}

export function caculateCompare(current, pass) {
  return +pass === 0 ? 0 : ((+current - +pass) * 100) / +pass
}

export function caculateCompareAvailableTime(current, pass) {
  return pass === 0 ? 0 : +current - pass
}

export function avtTimestamp(arr, first = true) {
  return first
    ? Math.round(arr.reduce((a, b) => a + b.firstActiveTime, 0) / arr.length)
    : Math.round(arr.reduce((a, b) => a + b.lastActiveTime, 0) / arr.length)
}

export function compareFirst(arr) {
  const arr2 = arr.filter((e) => e.firstActiveTime != 0)
  return Math.round(arr2.reduce((a, b) => a + b.first, 0) / arr2.length)
}
