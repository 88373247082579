import { configureStore } from "@reduxjs/toolkit";
import conversation from "./slices/conversation";
import analytic from "./slices/analytic";

export const store = configureStore({
  reducer: {
    analytic,
    conversation
  },
})
