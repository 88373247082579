/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import { logDOM } from '@testing-library/react'
import React, { createContext, useEffect, useState } from 'react'
import { apiService } from 'src/utils'

export const ToolContext = createContext()

const ToolProvider = ({ children }) => {
  const [step, setStep] = useState(1)
  const [load, setLoad] = useState(false)

  const [listRule, setListRule] = useState([])
  const [visible, setVisible] = useState(false)
  const [agents, setAgents] = useState([])
  const [listLimit, setListLimit] = useState([])
  const [showToast, setShowToast] = useState(false)
  const [rule, setRule] = useState({
    ruleName: '',
    conditions: 'equal',
    appName: '',
    agentsAssigned: [],
    criteria: 'contact_segment',
  })

  const updateListLimit = (userId, value) => {
    const limitClone = [...listLimit]
    const index = listLimit.findIndex((x) => x.userId === userId)
    if (index !== -1) {
      limitClone[index].limit = +value
    }
    setListLimit(limitClone)
  }

  const [validationRule, setValidationRule] = useState({
    step1: false,
    step2: false,
    step3: false,
  })

  const handleValidationRule = async (step, validate) => {
    const ruleClone = { ...validationRule }
    ruleClone[step] = validate
    setValidationRule(ruleClone)
  }

  const resetDefaultRule = () => {
    setRule({
      ruleName: '',
      conditions: 'equal',
      appName: '',
      agentsAssigned: [],
      criteria: 'contact_segment',
    })
  }

  const handleChangeRule = async (key, value) => {
    if (key === 'ruleName' && value.length) {
      await handleValidationRule('step1', true)
    } else {
      await handleValidationRule('step1', false)
    }
    // if (key === 'appName' && value.length) {
    //   await handleValidationRule('step2', true)
    // } else {
    //   await handleValidationRule('step2', false)
    // }
    const ruleClone = { ...rule }
    ruleClone[key] = value
    setRule(ruleClone)
  }

  const updateAgentAssign = (value) => {
    const initialAgent = [...rule.agentsAssigned]
    const index = initialAgent.indexOf(value)
    if (index !== -1) {
      initialAgent.splice(index, 1)
    } else {
      initialAgent.push(value)
    }
    setRule({ ...rule, agentsAssigned: initialAgent })
  }

  const submitFormRule = async (data) => {
    const res = await apiService.post('rule', {
      ...data,
      agentsAssigned: JSON.stringify(data.agentsAssigned),
    })
    if (res.id) {
      setListRule([...listRule, res])
    }
    resetDefaultRule()
    return res
  }

  const updateRule = async (id, data) => {
    const dataSubmit = {
      ...data,
      agentsAssigned: JSON.stringify(data.agentsAssigned),
    }
    const res = await apiService.put(`rule/${id}`, dataSubmit)
    const response = await apiService.get('rule')
    if (response) {
      setListRule(response)
    }
    resetDefaultRule()
    return res
  }

  const deleteRule = async (id) => {
    const res = await apiService.delete(`rule/${id}`)
    const listRuleClone = [...listRule]
    if (res.affected === 1) {
      setListRule(listRuleClone.filter((item) => item.id !== +id))
    }
  }

  const fetchAllAgent = async () => {
    const response = await apiService.get('user')
    if (response) {
      const result = response.filter((item) => item.isOperator && item.deactivateTime == '0')
      setAgents(result)
      const resultClone = [...result]
      const limit = resultClone.map((item) => ({ userId: item.userId, limit: item.limitSetting }))
      setListLimit(limit)
    }
  }
  useEffect(() => {
    fetchAllAgent()
  }, [])

  const saveLimit = async ({ userId, limit }) => {
    setLoad(true)
    await apiService.post('user/updateLimit', { userId, limit })
    setShowToast(true)
    fetchAllAgent()
    setLoad(false)
  }

  return (
    <ToolContext.Provider
      value={{
        step,
        setStep,
        rule,
        setRule,
        handleChangeRule,
        submitFormRule,
        updateAgentAssign,
        setListRule,
        listRule,
        setVisible,
        visible,
        deleteRule,
        resetDefaultRule,
        updateRule,
        handleValidationRule,
        validationRule,
        setAgents,
        agents,
        listLimit,
        updateListLimit,
        saveLimit,
        setShowToast,
        showToast,
        load,
      }}
    >
      {children}
    </ToolContext.Provider>
  )
}

export default ToolProvider
