import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import './scss/style.scss'
import GlobalProvider from './context/GlobalContext'
import ToolProvider from './context/ToolContext'
import { AuthProvider } from './hooks/useAuth'
import Login from './views/pages/login/Login'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'
import { ProtectRoute } from './components/ProtectedRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
class App extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <AuthProvider>
          <GlobalProvider>
            <Suspense fallback={loading}>
              <Routes>
                <Route path="*" name="Protect" element={<ProtectRoute />}>
                  <Route path="*" name="Home" element={<DefaultLayout />} />
                </Route>
                <Route path="/login" name="Login" element={<Login />} />
                <Route path="/404" name="Notfound" element={<Page404 />} />
                <Route path="/500" name="ServerError" element={<Page500 />} />
              </Routes>
            </Suspense>
          </GlobalProvider>
        </AuthProvider>
      </BrowserRouter>
    )
  }
}

export default App
