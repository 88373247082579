import { createSlice } from "@reduxjs/toolkit";
import apiService from "../../utils/apiServices";

export const initialState = {

};
export const conversation = createSlice({
  name: "conversation",
  initialState,
  reducers: {

  },
});

const getPlanPackages = () => {
  return async (dispatch) => {
    try {
      const data = await apiService.get("/settings/plan-packages");
      dispatch(pricingPlan.actions.setPlanPackages(data));
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const conversationActions = {
  ...conversation.actions,
  getPlanPackages,
};
export default conversation.reducer;
