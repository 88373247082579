import axiosConfig from './axiosConfig'

const apiService = {
  get: (endpoint, params = {}, options = {}) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    window.websiteId = urlParams.get('website_id')
    return axiosConfig.get(
      `/${endpoint}`,
      {
        params: {
          ...params,
          websiteId: process.env.REACT_APP_WEBSITE_ID,
        },
      },
      options,
    )
  },
  post: (endpoint, data = {}) => {
    return axiosConfig.post(`/${endpoint}`, data)
  },
  put: (endpoint, data = {}) => {
    return axiosConfig.put(`/${endpoint}`, data)
  },
  delete: (endpoint, params = {}, options = {}) => {
    return axiosConfig.delete(`/${endpoint}`)
  },
}

export default apiService
