import React from 'react'
import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export const ProtectRoute = () => {
  const { auth } = useAuth()
  const outlet = useOutlet()
  if (!auth) {
    return <Navigate to="/login" />
  }

  return <div>{outlet}</div>
}
