import { addDays, differenceInCalendarDays, isSameDay } from 'date-fns'
import moment from 'moment'
import React, { createContext, useState, useMemo, useEffect, Suspense } from 'react'
import { useAuth } from 'src/hooks/useAuth'
import { useSessionStorage } from 'src/hooks/useSessionStorage'
import { apiService } from 'src/utils'
import { blackList } from 'src/utils/blackList'

export const GlobalContext = createContext()

// eslint-disable-next-line react/prop-types
const GlobalProvider = ({ children }) => {
  const { user } = useAuth()
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const [visible, setVisible] = useState(false)
  const [isApply, setIsApply] = useState(false)
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(moment().clone().subtract(1, 'months').startOf('day').toISOString()),
    endDate: new Date(moment().endOf('day').toString()),
    key: 'selection',
  })
  const handleSelect = (date) => {
    setSelectionRange({
      ...date.selection,
      endDate: new Date(date.range1.endDate),
      startDate: new Date(date.range1.startDate),
    })
  }
  const defaultInputRanges = [
    {
      label: 'days up to today',
      range(value) {
        console.log('VALUE', value)
        if (value) {
          return {
            startDate: addDays(selectionRange.startDate, (Math.max(Number(value), 1) - 1) * -1),
            endDate: selectionRange.endDate,
            clearField: false,
          }
        }
        return { startDate: new Date(), endDate: new Date(), clearField: true }
      },
      getCurrentValue(range) {
        // console.log('RANGE', range)
        if (range.clearField) return ''
        if (!isSameDay(range.endDate, selectionRange.endDate)) return '-'
        if (!range.startDate) return '∞'
        return differenceInCalendarDays(selectionRange.endDate, range.startDate) + 1
      },
    },
  ]
  const [operators, setOperators] = useState([])
  const [opeWithTime, setOpeWithTime] = useState([])
  useEffect(() => {
    const fetchOperators = async () => {
      const data = await apiService.get('user')
      setOperators(
        data
          .filter(
            (e) =>
              e.isOperator &&
              (+e.deactivateTime === 0 || +e.deactivateTime >= selectionRange.startDate),
          )
          .map((item) => `${item.firstName} ${item.lastName}`),
      )
      setOpeWithTime(
        data
          .filter((e) => e.isOperator)
          .map((item) => {
            return {
              ...item,
              name: `${item.firstName} ${item.lastName}`,
            }
          }),
      )
    }

    fetchOperators()
  }, [selectionRange])
  const [apps, setApps] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchApps = async () => {
    setLoading(true)
    const response = await apiService.get('list-app', {})
    if (response.data?.length) {
      setApps(
        response.data
          .filter((e) => e.app_name && !blackList.find((item) => item === e.app_name))
          .map((item) => item.app_name),
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchApps()
  }, [])
  const memo = useMemo(() => {
    const endDate = selectionRange.endDate
    if (endDate.toString().includes('00:00:00')) {
      selectionRange.endDate = +endDate + 86399999
    }
    return {
      startDate: new Date(selectionRange.startDate).getTime(),
      endDate:
        new Date(selectionRange.startDate).getTime() === new Date(selectionRange.endDate).getTime()
          ? new Date(selectionRange.startDate).getTime() + 24 * 60 * 60 * 1000 - 1
          : new Date(selectionRange.endDate).getTime(),
    }
  }, [selectionRange])

  // useEffect(() => {
  //   console.log(newTerm)
  //   console.log(user)
  //   return () => {
  //     setNewTerm(false)
  //   }
  // }, [setNewTerm])

  return (
    <Suspense>
      {!loading && (
        <GlobalContext.Provider
          value={{
            handleSelect,
            fetchApps,
            selectionRange: memo,
            visible,
            setVisible,
            setIsApply,
            operators,
            apps,
            opeWithTime,
            defaultInputRanges,
            setSelectionRange,
          }}
        >
          {children}
        </GlobalContext.Provider>
      )}
    </Suspense>
  )
}

export default GlobalProvider
